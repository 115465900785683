.dhp-container {
    position: relative;
    display: block;
    width: 72%;
    min-height: 100vh;
    margin: 0 auto;
    /* background: linear-gradient(var(--bluish), white, white, transparent); */
}

.dhp-container .dhp-header {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 30px;
    align-items: center;
    justify-content: space-between;
    background: var(--special-blue);
    /* height: 250px; */
}

.dhp-header .header-text {
    display: block;
    position: relative;
}

.dhp-header .header-text h2 {
    display: block;
    position: relative;
    color: #222;
    margin-bottom: 20px;
    font-size: 24px;
}

.dhp-header .header-text p {
    position: relative;
    display: block;
    color: #444;
    width: 350px;
    margin-bottom: 20px;
    font-size: 14px;
}

.dhp-header .bar-code img {
    position: relative;
    display: block;
    width: 170px;
}

.dhp-body {
    position: relative;
    padding: 50px 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
}

.dhp-body .left-side-bar {
    display: block;
}

.dhp-body .left-side-bar .side-bar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px 0 20px 20px;
    border-left: 6px solid #666;
    cursor: pointer;
    transition: 0.5s ease;
    /* border-radius: 6px; */
    margin-bottom: 15px;
    background: #efefef55;
}

.dhp-body .left-side-bar .side-bar-item.active {
    border-left: 6px solid var(--bluish);
    background: #fff;
}

.dhp-body .left-side-bar .side-bar-item:hover:not(.active) {
    transform: translateY(-2px);
    background: #efefef66;
}

.dhp-body .left-side-bar .side-bar-item h2 {
    color: #888;
    margin-bottom: 15px;
    display: block;
    letter-spacing: 1px;
    font-size: 20px;
}

.dhp-body .left-side-bar .side-bar-item.active h2 {
    color: var(--bluish);
}

.dhp-body .left-side-bar .side-bar-item p {
    color: #666;
    display: block;
    font-size: 14px;
}

.dhp-body .left-side-bar .side-bar-item i {
    font-size: 20px;
    color: #666;
    display: block;
    margin-left: 15px;
    margin-right: 10px;
}

/* main-content */
.dhp-body .main-content {
    display: block;
    padding: 30px;
    box-shadow: 3px 5px 17px rgba(0, 0, 0, 25%);
}

.dhp-body .main-content .appointment,
.right-container .appointment {
    display: block;
}

.dhp-body .main-content .appointment h2,
.right-container .appointment h2 {
    display: block;
    position: relative;
    color: var(--bluish);
    font-size: 20px;
}

.dhp-body .main-content .appointment h2::before,
.right-container .appointment h2::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: -10px;
    height: 4px;
    background: var(--bluish);
    width: 120px;
}

.dhp-body .main-content .appointment h2::after,
.right-container .appointment h2::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -8px;
    height: 1px;
    background: var(--bluish);
    width: 100%;
}

.dhp-body .main-content .appointment .not-found,
.right-container .appointment .not-found,
.box-container .not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.right-container .appointment .not-found,
.box-container .not-found {
    padding: 20px;
}

.dhp-body .main-content .appointment .not-found>h4,
.right-container .appointment .not-found>h4,
.box-container .not-found > h4 {
    font-size: 24px;
    color: #888;
    letter-spacing: 1px;
    font-weight: 400;

}

.dhp-body .main-content .appointment .not-found>img,
.right-container .appointment .not-found>img,
.appointment .not-found>img {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 50px;
}

.box-container .not-found > img {
    margin-bottom: 20px;
}

.dhp-body .main-content .appointment button.medication,
.right-container .appointment button.medication,
.appointment button.medication {
    display: block;
    margin: 15px auto 30px auto;
}

.dhp-body .main-content .appointment .medications,
.right-container .appointment .medications,
.appointment .medications {
    display: block;
    position: relative;
    padding: 30px 10px;
    background: #f8f8f8;
    margin-bottom: 15px;
    border-left: 6px solid #CCC;
}

.dhp-body .main-content .appointment .medications>h3,
.right-container .appointment .medications>h3,
.appointment .medications> h3 {
    position: relative;
    display: inline-block;
    color: #666;
    font-weight: 400;
    margin-bottom: 20px;

}

.dhp-body .main-content .appointment .medications>h3::before,
.right-container .appointment .medications>h3::before,
.appointment .medications>h3::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #999;
    bottom: 0px;
    left: 0;
}


.dhp-body .main-content .appointment .medications-container,
.right-container .appointment .medications-container,
.appointment .medications-container {
    padding: 8px 5px;
    position: relative;
    display: block;
    cursor: pointer;
    transition: 0.3s ease;
}

.dhp-body .main-content .appointment .medications-container:hover,
.right-container .appointment .medications-container:hover,
.appointment .medications-container:hover {
    background: #dedede;
}

.dhp-body .main-content .appointment .medications-container .container-header>h3,
.right-container .appointment .medications-container .container-header>h3,
.appointment .medications-container .container-header>h3 {
    position: relative;
    display: block;
    color: var(--bluish);
    font-weight: 500;
    font-size: 16px;
}

.dhp-body .main-content .appointment .medications-container .container-body,
.right-container .appointment .medications-container .container-body,
.appointment .medications-container .container-body {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    gap: 10px;
    align-self: center;
    position: relative;

}

.dhp-body .main-content .appointment .medications-container .container-body>div>p,
.right-container .appointment .medications-container .container-body>div>p,
.appointment .medications-container .container-body>div>p {
    display: block;
    color: #777;
    font-weight: 400;
    font-size: 14px;
}


.dhp-body .main-content .appointment .medications-container .container-body>div:last-child,
.appointment .medications-container .container-body>div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* The preview button */
.attachment-preview {
    display: block;
    outline: none;
    background: var(--special-blue);
    color: #fff;
    border: 2px solid #aaa;
    padding: 3px 12px;
    cursor: pointer;
    border-radius: 16px;
}

.attachment-preview:hover {
    border: 2px solid #bbb;
    background: #bbb;
}

.dhp-body .main-content .appointment>.details,
.right-container .appointment>.details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.dhp-body .main-content .appointment>.details img,
.right-container .appointment>.details img {
    width: 150px;
    border-radius: 50%;
    margin: 40px 0;
}

.dhp-body .main-content .appointment>.details h3,
.right-container .appointment>.details h3 {
    display: block;
    color: #444;
    font-size: 20px;
    margin-bottom: 20px;
}

.dhp-body .main-content .appointment>.details h4,
.right-container .appointment>.details h4 {
    display: block;
    color: var(--bluish);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.dhp-body .main-content .appointment>.details h5,
.dhp-body .main-content .appointment>.details p,
.right-container .appointment>.details h5,
.right-container .appointment>.details p {
    display: block;
    color: #888;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}

.dhp-body .main-content .appointment>.details p,
.right-container .appointment>.details p {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.dhp-body .main-content .appointment>.details>a,
.right-container .appointment>.details>a {
    background: var(--bluish);
    font-size: 14px;
    color: #fff;
    border: none;
    outline: none;
    text-decoration: none;
    padding: 5px 18px;
    text-align: center;
    border-radius: 5px;

}

/* dhp cards */
.dhp-body .past-appointments {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 30px 0px;
    cursor: pointer;
}

.dhp-body .past-appointments .appt-card {
    display: block;
    width: 50%;
    position: relative;
    padding: 20px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 15%);
    border-radius: 6px;
    zoom: 0.85;
}

.dhp-body .past-appointments .appt-card .card-top {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.dhp-body .past-appointments .appt-card .card-top>img {
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 20px;
}

.dhp-body .past-appointments .appt-card .card-top .card-top-text {
    display: block;
}

.dhp-body .past-appointments .appt-card .card-top .card-top-text>h3 {
    color: var(--bluish);
    font-weight: 600;
    font-size: 16px;

}

.dhp-body .past-appointments .appt-card .card-top .card-top-text>h5 {
    color: #666;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;
}

.dhp-body .past-appointments .appt-card .card-top .card-top-text>span {
    color: #888;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
    position: relative;
    display: block;
}

.dhp-body .past-appointments .appt-card .card-top .card-top-text>span::before {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #999;
}

.dhp-body .past-appointments .appt-card .card-top .card-top-text>p {
    color: #888;
    font-weight: 400;
    font-size: 13px;
}

.dhp-body .past-appointments .appt-card h6 {
    display: block;
    text-transform: uppercase;
    color: #888;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.dhp-body .past-appointments .appt-card>span {
    display: block;
    width: 100%;
    background: var(--bluish);
    color: white;
    text-align: center;
    padding: 10px;
}

/* dhp nav-bar */
.nav-bar {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    background: var(--special-blue);
    padding: 0 30px;
}

.nav-bar .logo {
    display: block;
    position: relative;
    width: 60px;
    object-fit: contain;
    border-radius: 50%;
    overflow: hidden;
}

.nav-bar .logo>img {
    display: block;
    position: relative;
    width: 60px;
    border-radius: 50%;
    cursor: pointer;
}

.nav-bar .image {
    position: relative;
    display: flex;
    align-items: center;
    color: #888;
    font-size: 16px;
    width: auto;
}

.nav-bar .image>h5 {
    position: relative;
    display: inline-block;
    margin-right: 7px;
}

.nav-bar .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    background: #aaa;
}

.nav-bar .profile-img {
    display: block;
    position: relative;
    height: 40px;
    cursor: pointer;
    background: #efefef;
}

.nav-bar .img-flyout {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #fff;
    bottom: -50px;
    left: -100px;
    padding: 20px;
    z-index: 3;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 25%);
    border-radius: 10px;
    width: 130px;
}

.nav-bar .img-flyout::before {
    position: absolute;
    content: '';
    background: #fff;
    width: 10px;
    height: 12px;
    transform: rotate(45deg);
    background: green;
    left: -105px;
    bottom: -25px;
    z-index: 2;
}

.nav-bar .img-flyout>a {
    display: block;
    color: #666;
    text-decoration: none;
    font-size: 14px;
    margin: 6px 0;
}

.nav-bar .img-flyout>a:hover {
    color: var(--bluish);
}

.nav-bar .img-flyout>a>i {
    margin-right: 5px;
}


.nav-bar .links {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

}

.nav-bar .links>a {
    display: block;
    padding: 20px 15px;
    text-decoration: none;
    color: #888;
    transition: 0.5s ease;
}

.nav-bar .links>a.active {
    color: var(--bluish);
}

.nav-bar .links>a:hover:not(.active) {
    background: #008AC045;
    color: white;
}