/* width */
::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eee;;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    height: 30px;
    border-radius: 3px;
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(24, 104, 24, 0.5);
  }
