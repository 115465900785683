
.radio-group,
.radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

}

.radio-button {
    margin-left: 20px;
    cursor: ponter;
}

.radio-group .radio-button>label {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    margin-right: 20px;
    cursor: pointer;
}

.radio-group .radio-button:last-child>label {
    margin-right: 0;
}

.radio-button>label>input {
    display: inline-block;
    position: relative;
    appearance: none;
    transition: 0.3s ease-in-out;
}

.radio-button>label>input::before {
    position: absolute;
    content: '';
    width: 18px;
    height: 18px;
    background: #E5E5E5;
    border-radius: 50%;
    left: -22px;
    top: -8px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.radio-button>label>input:checked::before {
    background: var(--control-blue);
}

.radio-button>label>input:hover:not(:checked)::before {
    background: silver;
}

.radio-button>label>input::after {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    background: none;
    color: #fff;
    border-radius: 50%;
    left: -20px;
    top: -6px;
    cursor: pointer;
    display: none;
    transition: 0.3s ease-in-out;
}

.radio-button>label>input:checked::after {
    content: '\eed6';
    font-family: 'Icofont';
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 300;
}

.questions .answer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 27px;
    margin-bottom: 15px;
}

.check-box {
    margin-left: 32px;
    margin-bottom: 8px;
}

.check-box,
.check-box label {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    color: #666;
}

.check-box label > a {
    display: inline-block;
    color: var(--bluish);
    text-decoration: none;
    margin-left: 5px;
}

.check-box label > a:hover {
    color: #888;
    text-decoration: underline;
}

.check-box label>input.custom-checkbox {
    position: relative;
    outline: none;
    border: none;
    appearance: none;
    cursor: pointer;
}

.check-box label>input.custom-checkbox::before {
    position: absolute;
    content: '';
    width: 28px;
    height: 16px;
    background: #E9E9E9;
    border-radius: 8px;
    left: -32px;
    top: -7px;
    margin-right: 15px;
}

.check-box label>input.custom-checkbox::after {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    left: -30px;
    top: -5px;
    transition: 0.3s ease-in-out;
}

.check-box label>input.custom-checkbox:checked::before {
    background: var(--control-blue);
}

.check-box label>input.custom-checkbox:checked::after {
    left: -18px;
}
