.tab-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    padding: 0 !important;
    font-family: 'Poppins';
    border-bottom: 1px solid #eee;
}

.tab-container .tab {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 20px;
    color: #999;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: normal;
    transition: 0.2s ease;
    border-bottom: 3px solid transparent;
}

.tab-container .tab>i {
    display: inline-block;
    margin-right: 5px;
    font-size: 16px;
}

.tab-container .tab:hover:not(.tab.active) {
    border-bottom: 3px solid #ccc;
    cursor: pointer;
}

.tab-container .tab.active {
    color: var(--bluish);
    border-bottom: 3px solid var(--bluish);
    font-weight: normal;
}

/* tab page content */
.tab-page-content,
.tab-page-content .page-body {
    padding: 40px 10px;
    display: block;
    position: relative;
    background-color: #fafafa;
}

.tab-page-content .page-header h2 {
    position: relative;
    display: inline-block;
    color: #666;
    font-weight: 400;
    font-size: 18px;

}

.tab-page-content .page-header h2::before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #999;
    bottom: 0px;
    left: 0;
}

.tab-page-content .page-body .profile-table {
    display: table;
    border: none;
    width: 100%;

}

.tab-page-content .page-body .profile-table>tbody>tr:first-child,
.tab-page-content .page-body .profile-table>tbody>tr:last-child {
    width: 20%;
}

.tab-page-content .page-body .profile-table>tbody td {
    padding: 15px 0;
}

.tab-page-content .page-body .profile-table>tbody td:first-child {
    font-weight: 500;
    width: 150px;
    letter-spacing: 0.4px;
    font-size: 13px;
    color: var(--bluish);
}

.tab-page-content .page-body .profile-table>tbody td:nth-child(2) {
    font-weight: normal;
    font-size: 14px;
    width: 150px;
    letter-spacing: 0.6px;
    color: #888;
    width: calc(100% - 300px);
}

.tab-page-content .page-body .profile-table>tbody td:last-child {
    width: 150px;
    color: var(--bluish);
    cursor: pointer;
    font-size: 12px;
    text-align: right;
}

.tab-page-content .page-body .profile-table>tbody td:last-child:hover {
    text-decoration: underline;
}

.sel-items {
    background: #efefef;
    padding: 10px;
    margin-bottom: 20px;
    color: #666;
    font-size: 13px;
}

/* Others page */
.questions {
    display: block;
    position: relative;
    color: #888;
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
}

.questions .question {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.questions .question>p {
    display: inline-block;
    text-align: left;
    position: relative;
    color: var(--bluish);
    font-size: 14px;
    margin-bottom: 7px;
}

.questions .question>p>span {
    display: inline-block;
    margin-right: 20px;
    font-weight: 600;
    color: #888;
}

.others-botton-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 40px;
}
