body, * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.new-loader-bg {
    position: fixed;
    top: 0;
    left: 0;
    display: flex; /*display flex whenever needed to show*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: #444;
    z-index: 502;
    overflow: hidden;
    opacity: 0.7;
}

.new-loader-bg .new-loader-container {
    display: flex;
    position: relative;
    width: 350px;
    height: 120px;
    background: transparent;
    flex-direction: row;
    justify-content: space-between;
    -webkit-box-reflect: below -10px linear-gradient(transparent, rgba(0,0,0,0.3));
}

.new-loader-bg .new-loader-container > span {
    display: block;
    position: relative;
    background: rgba(255,255,255,0.3);
    width: 30px;
    height: 90%;
    border-radius: 18px;
    box-shadow: 0 8px 12px rgba(0,0,0,0.6),
                inset 1px 3px 5px rgba(0,0,0,0.2);
    border: 3px solid #fff;
}

.new-loader-bg .new-loader-container > span::before {
    content: '';
    position: absolute;
    display: block;
    border-radius: 50%;
    background: #fff;
    bottom: 0;
    left: -2px;
    border: 1px solid #aff;
    width: 26px;
    height: 26px;
    box-shadow: 0 5px 12px rgba(0,0,0,0.3);
    animation: animateNewLoader 1.5s ease-in-out infinite;
    animation-delay: calc(0.3s * var(--i));

}

.d-none {
    display: none!important;
}

@keyframes animateNewLoader {
    0%, 100% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-80px);
    }
}