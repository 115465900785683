.box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    padding-top: 20px;
    width: 200px;
    height: 270px;
    top: 0;
    right: 0;
    border-radius: 7px;
    background: #fff;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 25%);
    z-index: 2;
    zoom: 0.7;
}

.box h4 {
    color: var(--main-green);
    font-size: 18px;
    display: block;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}

.box .percent,
.box .percent svg {
    position: relative;
    width: 150px;
    height: 150px;
}

.box .percent svg circle {
    width: 150px;
    height: 150px;
    fill: none;
    stroke-width: 10;
    stroke: var(--bluish);
    transform: translate(5px, 5px);
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.box .percent svg circle:nth-child(1) {
    stroke-dashoffset: 0;
    stroke: #f2f2f2;
}

.box .percent svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 87) / 100);
    stroke: var(--main-green);
}

.box .percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
}

.box .percent .number h2 {
    font-size: 38px;
}

.box .percent .number h2>span {
    font-size: 24px;
}

.box .text {
    padding: 10px 0 0;
    color: var(--bluish);
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    margin-top: -15px;
    font-size: 24px;
}

/* Health completion status */
.health-status {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;
    background: var(--special-blue);
    padding: 0 30px;
}

.health-status h2 {
    position: relative;
    display: block;
    color: #222;
    font-size: 16px;
    font-weight: 500;
}

.health-status > div {
    display: block;
    position: relative;
    height: 6px;
    width: 100%;
}

.health-status span {
    position: relative;
    display: block;
    width: 100%;
    background: silver;
    height: 6px;
    margin: 3px 0;
    border-radius: 3px;
}

.health-status span.progress-status {
    position: absolute;
    content: '';
    display: block;
    width: 40%;
    z-index: 1;
    top: -3px;
    left: 0;
    background: var(--main-green);
    height: 6px;
}

.health-status h4 {
    position: relative;
    display: block;
    color: #666;
    font-weight: 500;
    font-size: 11px;
}