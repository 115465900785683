@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Poppins';
}

:root {
    --main-green: #088939;
    --bluish: #0080AE;
    --golden-rod: #a88e16;
    --light-golden-rod: #e4c020;
    --special-blue: #0080AE44;
    --lighter-special-blue: #0080ae1f;
    --lighter-text: #808080;
    --control-blue: #0080AEAA;
    --success-bg: rgba(159, 218, 159, 0.6);
    --purplish: #aa24ab;
}

.header-bar {
    font-family: 'Poppins';
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    background: var(--bluish);
    padding: 0 30px;
    box-shadow: 2px 5px 12px rgba(0, 0, 0, 0.2);
    z-index: 5;
}

.header-bar-image {
    background: transparent;
    color: white;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
}

.header-bar-image>a {
    display: flex;
    align-items: center;
    color: #efefef;
    letter-spacing: 1.5px;
    font-size: 32;
    font-weight: bold;
    text-decoration: none;
}

.header-bar-image>a>img {
    padding: 0 10px;

}

/* Container for login...  */
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(135deg, white, white, transparent, var(--bluish), var(--bluish));
    z-index: 1;
}

.container .smaller-container {
    display: block;
    width: 75%;
    min-height: 70%;
}

.container .container-divider {
    display: grid;
    grid-template-columns: 3fr 4fr;
    gap: 0;
    height: 100%;
}

.container .left-sidebar {
    display: block;
    padding: 40px;
    height: auto;
}

.container .float-logo {
    display: block;
    position: absolute;
    top: calc(50% - 125px);
    left: 50px;
}

.container .float-logo>i {
    position: absolute;
    font-size: 250px;
    opacity: 0.5;
    color: var(--bluish);
}

.container .left-sidebar .left-text {
    display: block;
    margin-top: 20px;
    position: relative;

}

.container .left-sidebar .left-text>h2 {
    font-weight: 500;
    color: #0080AE;
    font-size: 30px;
    margin-bottom: 20px;
    position: relative;
}

.container .left-sidebar .left-text>h4 {
    font-weight: bold;
    font-family: Poppins;
    margin-bottom: 15px;
    color: #444;
    position: relative;
}

.phone-input-container {
    display: block;
    width: calc(100% - 35px);
    height: 35px;
    margin-bottom: 35px;
    position: relative;
}

.container .left-sidebar .left-text .login-control {
    display: block;
    position: relative;
    outline: none;
    border: 1px solid lightgrey;
    padding: 12px 20px;
    font-size: 18px;
    width: calc(100%);
    border-radius: 3px;

}

.login-control::placeholder {
    color: rgb(185, 182, 182);
}

.container .left-sidebar .left-text h5 {
    font-weight: 600;
    color: #888;
    display: block;
    margin-bottom: 7px;
    font-size: 14px;
}

.container .left-sidebar .left-text h5~a {
    /* Terms and conditions */
    display: block;
    position: relative;
    margin-bottom: 50px;
    font-weight: bold;
    color: #0080AE;
}

.container .left-sidebar .left-text>button,
.left-text .btn-continue {
    display: block;
    width: 100%;
    outline: none;
    border: none;
    background: #0080AE;
    color: #FFF;
    padding: 9px 25px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 40px;
    position: relative;
    font-family: Poppins;
}

.btn-continue {
    margin-top: 20px;
}

.container .left-sidebar .doctor-register h4 {
    display: block;
    color: #444;
    position: relative;
    font-size: 14px;
    color: #888;
    font-weight: 500;
}

.container .left-sidebar .doctor-register h4>a {
    display: inline-block;
    position: relative;
    font-weight: 600;
    color: #0080AE;
    margin-left: 7px;
    font-size: 14px;
}

.left-footer {
    display: flex;
    position: absolute;
    bottom: 40px;
    margin: 10px auto;
    align-self: flex-end;
    justify-content: center;
    font-weight: normal;
    color: #444;
}

.left-footer p {
    text-align: center;
}


/* Right sidebar - login */
.container .right-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px;
    background: #0080AE;
    height: 100%;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 25%);
}

.container .right-sidebar>img {
    display: block;
    width: 80%;
    margin-bottom: 50px;
}

.container .right-sidebar .image-text {
    display: block;
    position: relative;
}

.container .right-sidebar .image-text>p {
    font-weight: 18px;
    color: #efefef;
    font-size: 35px;
    font-weight: 300;
    text-align: center;
}

/* Input group etc */
.form-data {
    display: block;
    width: 100%;
    position: relative;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #dedede;
    padding: 35px 20px;
    margin-bottom: 30px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
}

.form-data h2 {
    position: absolute;
    left: 20px;
    top: -17px;
    color: var(--bluish);
    font-weight: 600;
    background: transparent;
    font-size: 20px;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
}

.form-row-1-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    position: relative;
}

.form-row-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    position: relative;
}

.form-row-2-1 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 20px;
    position: relative;
}

.form-row-2-1-1 {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 20px;
    position: relative;
}

.form-row-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    position: relative;
}

@media screen and (max-width: 767px) {

    .form-row-2,
    .form-row-2-1-1,
    .form-row-2-1,
    .form-row-3,
    .form-row-1-2 {
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 550px) {

    .form-row,
    .form-row-2,
    .form-row-2-1-1,
    .form-row-3,
    .form-row-4,
    .form-row-1-2 {
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 20px;
    }

    .input-group {
        margin-bottom: 0 !important;
    }
}

.input-group {
    display: block;
    position: relative;
    margin-bottom: 15px;
}

.input-group>.form-control~label {
    font-weight: 400;
    font-size: 14px;
    color: #666;
    display: block;
    position: absolute;
    color: var(--lighter-text);
    top: 10px;
    left: 15px;
    transition: 0.4s ease;
}

.input-group>.form-control~span {
    display: block;
    margin-top: 5px;
    color: var(--bluish);
    font-size: 13px;
    text-align: left;
}

.input-group>label {
    font-weight: 400;
    font-size: 14px;
    color: #666;
    display: block;
    position: relative;
    margin-bottom: 5px;
    color: var(--lighter-text);
    pointer-events: none;
    background: #fff;
}

.input-group>label>span {
    /* This is for an error message */
    color: red;
    margin-right: 2px;
}

.form-control {
    display: block;
    outline: 0;
    border: 1px solid #ddd;
    padding: 10px 18px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px;
    color: #666;
    font-family: Poppins;

}

.form-control:focus~label,
.form-control:valid~label {
    transform: translateY(-17px);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: var(--bluish);
    font-size: 13px;
    padding: 0 5px;
}

.form-control input[type=tel] {
    display: block;
    outline: 0;
    border: none;
    padding: 10px 18px;
    width: 100%;
    border-radius: 3px;
    font-size: 16px;
    color: #888;
    font-family: Poppins;
    letter-spacing: 1px;
    font-weight: 500;
}

.input-group-doctor .form-control input[type=tel] {
    font-size: 14px;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
    box-shadow: 0 0 4px rgba(40, 70, 190, 0.4);
}

.form-control input[type=tel]:focus {
    border: none;
    box-shadow: none;
}

select.form-control {
    width: 100%;
}

/* Buttons */
button:focus {
    box-shadow: 0 0 0 var(--transparent-green);
}

.btn-main {
    position: relative;
    display: inline-block;
    border: 1.5px solid var(--bluish);
    outline: none;
    padding: 5px 25px;
    background: var(--bluish);
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    border-radius: 3px;
    font-family: Poppins;
    transition: 0.3s ease;
}

.btn-sm {
    font-size: 12px;
    padding: 3px 15px;
}

.btn-main-outline {
    position: relative;
    display: inline-block;
    border: 1.5px solid var(--bluish);
    outline: none;
    padding: 5px 25px;
    color: var(--bluish);
    cursor: pointer;
    text-decoration: none;
    background: #fff;
    border-radius: 3px;
    font-family: Poppins;
    transition: 0.3s ease;
}

.btn-main:hover {
    background: transparent;
    color: var(--bluish);
}

.btn-main-outline:hover {
    background: var(--bluish);
    color: #fff;
}

.link-main {
    position: relative;
    display: inline-block;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
    color: var(--bluish);
    font-family: Poppins;
}

.link-main:hover {
    opacity: 0.8;
}

/* Main body parts */
.body-container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    font-family: 'Poppins';
    z-index: 4;
    width: 80vw;
    margin: 0 auto;
}

@media screen and (max-width: 760px) {
    .body-container {
        grid-template-columns: 1fr;
    }

    .body-container .left-container {
        padding: 30px;
    }
}

.body-container .left-container,
.body-container .right-container {
    display: block;
    position: relative;
    background: #fff;
    padding: 40px;
}

.body-container .left-container img {
    width: 100%;
    max-width: 250px;
    display: block;
    position: relative;
    object-fit: cover;
    margin: 20px 0 40px 0;
    box-shadow: 3px 12px 16px rgba(0, 0, 0, 0.15);

}

/* Doctor's allow view */
.doctor-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #efefef;
    padding: 15px;
    margin-top: 30px;
}

.doctor-view>div>i {
    margin-right: 5px;
    color: var(--main-green);
    font-size: 18px;
}

.doctor-view>div>span {
    color: #555;
    font-size: 16px;
}

.doctor-view button {
    display: inline-block !important;
}

.line-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 3px solid lightgrey;
    margin-bottom: 40px;
}

.line-container h4 {
    position: absolute;
    background: #efefef;
    color: #777;
    padding: 5px 10px;
    top: -15px;
    left: 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 13px;
}

/* Right panel - Body container */
.body-container .right-container {
    display: block;
    position: relative;
    margin-top: 20px;
    padding-left: 10px;
}


.body-container .right-container .title {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;

}

.body-container .right-container .title h2 {
    position: relative;
    color: var(--bluish);
    font-weight: 700;
    letter-spacing: 1.5px;
    font-size: 34px;
    text-transform: uppercase;
}

.body-container .right-container .title h2::before {
    position: absolute;
    content: '';
    bottom: -7px;
    left: 0;
    height: 4px;
    background: #888;
    width: 70px;
    border: none;
}

.body-container .right-container .title h4 {
    color: #888;
}

.body-container .right-container>h3 {
    display: block;
    color: var(--bluish);
    font-size: 15px;
}

.right-container .upcoming-appointment {
    display: block;
    position: relative;
    margin: 50px 0 40px 0;
}

.right-container .upcoming-appointment>.title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    color: grey;
}

.right-container .upcoming-appointment .appointment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: #efefef46;
    padding: 30px;
    box-shadow: 3px 5px 17px rgba(0, 0, 0, 15%);
    border-radius: 8px;
}

.right-container .upcoming-appointment .appointment>img {
    display: inline-block;
    position: relative;
    width: 120px;
    border-radius: 50%;
}

.right-container .upcoming-appointment .appointment .details {
    display: block;
    color: #444;
}

.right-container .upcoming-appointment .appointment .details h2 {
    margin-bottom: 5px;
}

.right-container .upcoming-appointment .appointment .details h3 {
    margin-bottom: 10px;
    font-weight: normal;
    color: #999;
    font-size: 14px;
}

.right-container .upcoming-appointment .appointment .details h4 {
    color: #888;
    font-size: 14px;
    font-weight: 600;
}

.right-container .upcoming-appointment .appointment .details>a {
    display: block;
    text-align: center;
    background: var(--bluish);
    color: white;
    padding: 5px 15px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
}

.right-container .upcoming-appointment .appointment .details>a:hover {
    opacity: 0.8;
}

/* Book appointment */

.book-appointment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: relative;
    margin-bottom: 20px;
    background: transparent;
    padding: 15px;
    border-radius: 4px;
}

.book-appointment>img:first-child {
    width: 50%;
    margin-bottom: 30px;
}

.book-appointment>img.warning {
    width: 60px;
    position: absolute;
    transform: translateY(-40px);
}

.book-appointment>h4 {
    display: block;
    font-weight: 300;
    font-size: 18px;
    font-family: Poppins;
    color: #777;
    margin-bottom: 30px;
    margin-right: 20px;
    text-align: center;
}


/*==============================================
    EMAIL BOX
===============================================*/
.emails-box {
    width: 100%;
    display: block;
    position: relative;
    background: gainsboro;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 5%);
    padding: 15px;
    font-size: 16px;
    text-align: center;
    color: var(--lighter-text);
}

.emails-box>span {
    padding: 7px 13px;
    display: inline-block;
    position: relative;
    background: rgb(196, 195, 195);
    color: black;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 13px;
    transition: 0.3s ease;

}

.emails-box>span:hover {
    box-shadow: 3px 5px 8px rgba(0, 0, 0, 25%);
}


/*==============================================
    SPECIAL FILE UPLOAD
===============================================*/

.upload-file-area {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* width: 350px; */
    background: #EFEFEF55;
    border: 2px dotted silver;
    border-radius: 4px;
    padding: 15px;
    font-family: Poppins;
}

.upload-file-area>div {
    display: flex;
}

.upload-file-area .btn-upload {
    display: inline-block;
    outline: none;
    border: none;
    background: none;
    color: var(--bluish);
    font-size: 11px;
    padding: 0;
    margin-left: 3px;
    transition: 0.3s;
    cursor: pointer;
}

.upload-file-area .btn-upload:hover {
    text-decoration: underline;
    background: none;
}

.upload-file-area .btn-upload:focus {
    box-shadow: none;
}

.upload-file-area i {
    font-size: 16px;
    color: dimgrey;
    margin-right: 5px;
}

.btn-grey {
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border: 1px solid #aaa;
    outline: none;
    padding: 5px 15px;
    background: linear-gradient(gainsboro, whitesmoke);
    text-decoration: none;
    color: #000;
    font-size: 12px;
}

.btn-grey {
    padding: 2px 7px;
    font-size: 12px;
}

.btn-grey:hover {
    background: linear-gradient(silver, gainsboro);
}

.upload-file-area .btn-upload>input[type=file] {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.upload-file-area .control-info {
    font-size: 12px;
    margin-top: 5px;
    color: var(--bluish);
}

.upload-file-area label {
    display: block;
    font-weight: normal;
    font-size: 10px;
}

.upload-file-area>label>span {
    color: var(--main-green);
    font-size: 10px;
}

.upload-file-area>span {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--medium-text)
}

.upload-file-area>span:last-child {
    margin-bottom: 0;
    color: var(--main-green)
}

.d-block {
    display: block!important;
}

.d-flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mt-3 {
    margin-top: 30px!important;
}

.ml-2 {
    margin-left: 20px !important;
}

.mr-2 {
    margin-right: 20px !important;
}

.pl-2 {
    padding-left: 20px!important;
}

.p-2 {
    padding: 20px!important;
}

.pt-2 {
    padding-top: 20px!important;
}

.pb-2 {
    padding-bottom: 20px!important;
}

.px-2 {
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.py-2 {
    padding-top: 20px!important;
    padding-bottom: 20px!important;
}

.border-left {
    border-left: 1px solid #ddd;
}

.border-top {
    border-top: 1px solid #ddd;
}

.border-bottom {
    border-bottom: 1px solid #ddd;
}

.align-right {
    text-align: right!important;
}

.align-left {
    text-align: left!important;
}

.amount-label {
    line-height: 42px;
    color: #888;
    font-size: 15px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0;
}

.section-header {
    display: inline-block;
    position: relative;
    margin: 20px 0 0;
    color: #666;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.7px;
}


/* Schedule Appointment */

.doctor-area {
    display: block;
    position: relative;
    margin: 20px 0;
}

.doctor-area .chosen-doctor {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 4fr;
    gap: 15px;
    padding: 20px;
    width: 100%;
    background: #fafafa;
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 15%);
}

.doctor-area .chosen-doctor .image-cont, .image-cont {
    display: flex;
    position: relative;
    width: 140px;
    height: 140px;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

.doctor-area .chosen-doctor .image-cont > img, .image-cont > img {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2),
        -2px -2px 2px rgb(255, 255, 255, 1);
}

.doctor-area .chosen-doctor .details {
    display: block;
    position: relative;
}

.doctor-area .chosen-doctor .details h3 {
    font-family: Poppins;
    font-weight: 600;
    font-size: 22px;
    color: var(--main-green);
}

.doctor-area .chosen-doctor .details p {
    color: #aaa;
    font-size: 14px;
    margin-top: -2px;
    margin-bottom: 8px;
}

.doctor-area .chosen-doctor .details>div {
    display: flex;
    flex-direction: row;
}

.doctor-area .chosen-doctor .details>div>span {
    display: flex;
    position: relative;
    background: #ddd;
    text-align: center;
    padding: 2px 14px;
    border-radius: 12px;
    color: #444;
    margin-right: 10px;
    font-size: 13px;
}

.doctor-area .no-doctor-yet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #eee;
    border: 3px dashed #bbb;
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    color: var(--bluish);
}

.doctor-area .no-doctor-yet h2 {
    font-weight: 400;
    margin-top: 5px;
}

/* Special doctors dialog items on patients selection */

.doctors-list-container {
    display: block;
    padding: 10px;
    height: 300px;
    background: #fff;
    position: relative;
    border: 2px solid lightgrey;
    scroll-behavior: smooth;
    overflow-y: scroll;
}

.doctors-list-container .doctor-area {
    margin-bottom: 25px;
    background: gainsboro;
}

.doctors-list-container .doctor-area.selected {
    background: rgba(162, 165, 162, 0.5);
}

.doctors-list-container .doctor-area .details>div>span {
    font-weight: 500;
    cursor: pointer;
    padding: 3px 7px 2px;
}

.doctors-list-container .doctor-area .details>div>span:hover:not(.blue) {
    background: #88d8;
}

.doctors-list-container .doctor-area .details>div>span.blue {
    background: var(--bluish);
    color: white;
}

.doctors-list-container .doctor-area .details .schedule {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 7px;
}

.doctors-list-container .doctor-area .details .schedule>p {
    margin-top: 5px;
    font-size: 12px;
    color: #999;
    text-transform: uppercase;
    font-weight: 600;
}

.doctor-area .chosen-doctor .costing {
    margin-top: 0px;
}

.costing>p {
    font-size: 12px;
    font-weight: 500;
    background: #88b8;
    color: var(--main-green) !important;
    padding: 3px 12px;
}

/* Application Status */
.application-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 200px;
    height: 250px;
    top: 0;
    right: 0;
    border-radius: 7px;
    background: #fff;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 25%);
    z-index: 2;
    zoom: 0.7;
}

.application-status h4 {
    color: var(--main-green);
    font-size: 18px;
    display: block;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}

.application-status h2 {
    color: var(--main-green);
    font-size: 32px;
    display: block;
    text-align: center;
    line-height: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.application-status p {
    display: block;
    color: #888;
}

.application-status .outer-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to left, rgb(86, 122, 92), rgb(54, 126, 66), rgb(56, 102, 64), rgb(54, 126, 66));
    width: 160px;
    height: 160px;
    border-radius: 50%;
}

.application-status .inner-circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.application-status .arc {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    flex-direction: column;
    transform-origin: 100% 100%;
    width: 140px;
    height: 140px;
    /* transform: rotate(75deg) skew(60deg); */

}

.span-in-span {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    color: #666;
    margin-bottom: 15px;
    font-size: 13px;
}

.span-in-span>span {
    color: var(--main-green);
    background: #efefefaa;
    padding: 5px 9px;
}

.span-in-span>span>span {
    color: #666;
}

.meeting-button-container {
    padding: 20px;
    background: #888;
    width: 20vw;
    position: absolute;
    top: 25px;
    right: 25px;
    border-radius: 7px;
    height: auto;
}

/* Error border */
.error-border {
    border: 1px solid red;
}

/* 404 page */
.page-404-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 600px;
    transform: translateY(-40px);
}

.page-404-container>h1 {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 400;
    font-size: 50px;
    color: #666;
    text-shadow: 30px 60px 5px rgba(0, 0, 0, 0.15);

}

@keyframes drop-in {
    0% {
        opacity: 0;
        transform: translate(0px, -100px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.page-404-container>img {
    width: 100%;
}

.page-404-container .buttons-404 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;

}

.page-404-container .buttons-404>button,
.page-404-container .buttons-404>a {
    position: relative;
    animation: drop-in 0.7s linear;
    animation-delay: 0.5s;
    display: inline-block;
    outline: none;
    border: none;
    padding: 8px;
    background: var(--special-blue);
    font-family: Poppins;
    text-decoration: none;
    color: #444;
    width: 170px;
    text-align: center;
    font-size: 15px;
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

.page-404-container .buttons-404>button:hover,
.page-404-container .buttons-404>a:hover {
    background: #0080AE;
    color: #fff;
}

/* hr */

hr {
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
    border: 0.5px solid #efefef;
    margin: 20px 0;
}

/* information-boxes */
.information-box {
    display: flex;
    width: 100%;
    padding: 20px;
    border: 3px solid #bbb;
    border-radius: 7px;
    background: #0080ae2a;
}

.information-box > i {
    display: block;
    color: #0080aed5;
    font-weight: bold;
    font-size: 22px;
}

.information-box > p {
    display: block;
    margin-left: 10px;
    color: #555;
    font-size: 13px;
}

/* Payment Summary */
.payment-summary {
    display: block;
    position: relative;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    background: #fff;
}

.appt-settings {
    display: block;
    position: relative;
    margin-top: 10px;
}

.appt-settings label {
    font-size: 13px;
}

.payment-summary h3, .appt-settings h3 {
    display: block;
    position: relative;
    font-size: 16px;
    color: #666;
    font-weight: 600;
    margin-bottom: 20px;

}

.appt-settings h3 {
    margin-bottom: 10px;
}

.payment-summary > .payment-summary-table {
    display: table;
    position: relative;
    border-collapse: collapse;
    border: none;
    background: #efefef88;
    width: 100%;
    margin-bottom: 20px;
}

.payment-summary > .payment-summary-table tr {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 14px 20px;
    align-items: center;
    justify-content: space-between;
    color: #888;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
}

.payment-summary > .payment-summary-table tr:last-child {
    background: var(--lighter-special-blue);
    color: #333;
    font-size: 13px;
    font-weight: 600;
}

.payment-summary p {
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 12px;
    color: #888;
}

.payment-summary p>a {
    color: var(--bluish);
    text-decoration: none;
    cursor: pointer;
}

.payment-summary p>a:hover {
    color: #666;
}

/* Wallet Balance */
.wallet-balance {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
}

.wallet-balance span {
    display: block;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 2px;
    background: var(--bluish);
    letter-spacing: 0.5px;
}

.wallet-balance i {
    display: block;
    position: relative;
    font-size: 32px;
    margin-right: 7px;
    color: #444;
}